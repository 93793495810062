"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isValidSavedInstructionColor = exports.SavedInstructionColor = void 0;
const typebox_1 = require("@sinclair/typebox");
const json_schema_1 = require("../json-schema");
/**
 * JSON Schema definition for valid saved instruction colors.
 */
exports.SavedInstructionColor = typebox_1.Type.Union([
    typebox_1.Type.Literal('gray'),
    typebox_1.Type.Literal('blue'),
    typebox_1.Type.Literal('green'),
    typebox_1.Type.Literal('orange'),
    typebox_1.Type.Literal('yellow'),
    typebox_1.Type.Literal('red'),
]);
/**
 * Function to validate a given value is a email.
 */
exports.isValidSavedInstructionColor = (0, json_schema_1.createValidator)(exports.SavedInstructionColor);
